@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/Poppins/Poppins-normal-100.ttf) format('truetype'), url(../fonts/Poppins/Poppins-normal-100.woff2) format('woff2'), url(../fonts/Poppins/Poppins-normal-100.woff) format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Poppins/Poppins-normal-300.ttf) format('truetype'), url(../fonts/Poppins/Poppins-normal-300.woff2) format('woff2'), url(../fonts/Poppins/Poppins-normal-300.woff) format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Poppins/Poppins-normal-400.eot);
  src: local('Poppins'), url(../fonts/Poppins/Poppins-normal-400.ttf) format('truetype'), url(../fonts/Poppins/Poppins-normal-400.woff2) format('woff2'), url(../fonts/Poppins/Poppins-normal-400.woff) format('woff'), url(../fonts/Poppins/Poppins-normal-400.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins/Poppins-normal-400.svg#Poppins) format('svg');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Poppins/Poppins-normal-700.ttf) format('truetype'), url(../fonts/Poppins/Poppins-normal-700.woff2) format('woff2'), url(../fonts/Poppins/Poppins-normal-700.woff) format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/Poppins/Poppins-normal-900.ttf) format('truetype'), url(../fonts/Poppins/Poppins-normal-900.woff2) format('woff2'), url(../fonts/Poppins/Poppins-normal-900.woff) format('woff');
}

