@use "assets/css/buttons.scss";
@use "assets/css/colors";

//bootstrap icons
@import 'bootstrap-icons/font/bootstrap-icons.css';
//intl-tel-input
@import 'intl-tel-input/build/css/intlTelInput.css';


$darkGray: #d3d3d3;
app-reusable-button button {
  width: 100%;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  margin: 0 !important;
}

body * {
  font-family: 'Poppins', sans-serif;
}

app-root {
  width: 100%;
  height: 100%;
  display: block;
  overflow-y: auto;
}

//Overriding bootstrap styles
.form-control, .form-select {
  border-radius: 100px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: solid 2px green !important;
  font-size: 1.2rem !important;
}

.card {
  border: 2px solid #d3d3d3 !important;
}

.card-body {
  padding: 24px 20px 21px 20px !important;
}

.form-control:not(.is-valid), .form-select:not(.is-valid) {
  border: solid 2px #d3d3d3 !important;
} 

label {
  margin-bottom: 4px;
  padding-left: 14px;
  font-weight: bold;
  color: #414141;
}

h5, p, h1 {
  text-align: center;
  color: #414141;
}

h5 {
  margin-bottom: 18px !important;
}


label.custom-checkbox {
  color: #2c2e31;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 12px;
  line-height: 1.8;
}
/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox */
.custom-checkbox .checkmark {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: white;
  margin-right: 12px;
  position: relative;
  top: 4px;
  border: 2px solid colors.$crediFlowPrimary;
  border-radius: 3px;
}

/* When the checkbox is checked, add a checkmark */
.custom-checkbox input[type="checkbox"]:checked + .checkmark::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid colors.$crediFlowSecondary;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}


.sentry-error-embed-wrapper {
  display: none !important;
}
