@use "colors.scss";

.btn-primary {
  background: colors.$lightBlue !important;
  color: colors.$white !important;
}

.btn-primary:hover {
  background: colors.$darkBlue !important;
  color: colors.$white !important;
}

.h-48 {
  height: 48px !important;
}

.btn-primary {
  color: #fff !important;
  background: #842ea9  !important;
  letter-spacing: -0.025rem;
  text-transform: none  !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)  !important;
  border: none !important;
}

.btn-primary:hover:not(.btn-icon-only) {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)  !important;
  transform: translateY(-1px)  !important;
}


.btn-primary:hover, .btn.bg-gradient-primary:hover {
  background-color: #842ea9  !important;
  border-color: #842ea9  !important;
}
