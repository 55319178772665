/* Primary Colors ------------------------------------------------------------ */
$agilCred: #ec2135;
$darkGrey: #2c2e31;
$lightBlue: #1095b1;

/* Secondary Colors ------------------------------------------------------------ */
$white: #ffffff;
$lightGrey: #f3f0ec;
$grey: #cfcfcf;
$greyTwo: #747474;
$black: #181818;
$agilCredLight: #f23f50;
$error: #ffeff1;
$yellow: #ffd700;
$darkBlue: #015769;

/* Crediflow Colors ---------------------------------------------------------- */

$crediFlowPrimary: rgb(132, 46, 169);
$crediFlowPrimaryDark: rgb(104, 28, 136);
$crediFlowPrimaryLight: rgb(163, 57, 209);
$crediFlowSecondary: rgb(54, 193, 123);
$crediFlowSecondaryDark: rgb(32, 161, 96);
$crediFlowSecondaryLight: #41d58b;
$crediFlowDisabled: rgb(203, 203, 203);

